import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.audiomack',
    defaultMessage:
      'Audiomack is a completely free platform for content creators to share their music without limitations or restrictions. It focuses exclusively on new, independent, and up-and-coming artists. They are not a full-catalog streaming service like Spotify and Apple Music, and do not plan to ever become one.'
  }
});
export const audiomack: MusicService = {
  id: 'audiomack',
  shortName: 'Audiomack',
  name: 'Audiomack',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#FFA200',
  logo: {
    light: nextAsset('images/music-services/audiomack-light.svg'),
    dark: nextAsset('images/music-services/audiomack-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/audiomack-light-padding.svg'),
    dark: nextAsset('images/music-services/audiomack-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/audiomack-color-light.svg'),
    dark: nextAsset('images/music-services/audiomack-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/audiomack-color-light-padding.svg'),
    dark: nextAsset('images/music-services/audiomack-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/audiomack-dark.svg'),
  whatIsDescription
};
